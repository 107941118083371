import React from 'react';

const Resources = () => {
  return (
    <section>
    <section>
    <div className="container-fluid">
      <div className="row page-titlebar">
        <h2 className="page-title text-center">Disability Development Program</h2>
        <div class="d-flex justify-content-center">
          <div class="dynamic-column text-center breadcrumbs p-3 ">
            Home | <span>Disability Development Program</span>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div >
      <h2 >Disability Development Program</h2>
      <p>
      VASD provided services to near about 2100 persons with disabilities.
Following major activities have been implementing under the program:
Awareness raising of community people on disability issue
Identification of persons with disabilities
Assessment disability of identified persons with disabilities
Awareness rising and capacity building of persons with disabilities
 Provide primary Rehabilitation Therapy [PRT] to persons with Disabilities
Provide referral services to persons with Disabilities in different specialized hospital/service centers
Provide medical services to persons with disabilities
Provide Assistive Devices to persons with disabilities
Provide life skill training to person with disabilities/families members
Provide loan support at less interest among the disabled people  to rehabilitate them in various IGA sector
 Enroll children with disabilities into school and provide education support
Make schools disable children friendly
Form Self-help Group of persons with disabilities and building capacity
Advocacy and liaison with different government and non-government service providing institutes in favor of persons with disabilities
 Networking with other disable based organization, forum and stakeholders
      </p>
      <p>
       
      </p>
    </div>
    </section>
  );
};

export default Resources;
