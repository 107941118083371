import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const ContactUs = () => {
  const sectionBg = {
    backgroundColor: '#002866',
    color: '#ffffff',
  };

  const containerStyle = {
    width: '100%',
    height: '400px',
  };
  
  const center = {
    lat: 37.7749, // San Francisco latitude
    lng: -122.4194, // San Francisco longitude
  };



  return (
    <section>
      <section>
      <div className="container-fluid">
        <div className="row page-titlebar">
          <h2 className="page-title text-center">Contact Us </h2>
          <div class="d-flex justify-content-center">
            <div class="dynamic-column text-center breadcrumbs p-3 ">
              Home | <span>Contact Us</span>
            </div>
          </div>
        </div>
      </div>
      </section>

      <section style={sectionBg}>
        <div className="container">
          <div className="row ">
            {/* Left side: Google Form */}
            <div className="col-md-6">
              <div className="mb-4">

                <div className='fs-1'> <strong>Want To Get In Touch?</strong></div>


                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                    <i className="bi bi-geo-alt-fill text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Visit Us</strong></h3>
                    <p>
                      House # 77/3, Block-A, Talbag,<br />
                      Upazila: Savar,<br />
                      District: Dhaka, Dhaka-1340, <br />
                      Country:  Bangladesh.<br />
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                  <i class="bi bi-headset text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Phone Us</strong></h3>
                    <p>
                      Telephone: 02-7743925,
                      </p>   <p>
                      Cell: +8801711 563174
                      </p>   <p>
                      E-mail: vasd1995@yahoo.com
                    </p>
                  </div>
                </div>

                <div class="d-flex align-items-center">
                  <div class="me-4 fs-1">
                  <i class="bi bi-envelope-check text-color-yellow"></i>
                  </div>

                  <div>
                    <h3 className='fs-4'><strong>Email Us</strong></h3>
                   <p>
                      E-mail: vasd1995@yahoo.com
                    </p>
                  </div>
                </div>
                </div>



            </div>

            {/* Right side: Google Map */}
            <div className="col-md-6">
            
            <LoadScript googleMapsApiKey="YOUR_GOOGLE_MAPS_API_KEY">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
           
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ContactUs;
