import React from 'react';

const DisabilityDevelopmentProgram = () => {
  return (

    <section>
    <section>
    <div className="container-fluid">
      <div className="row page-titlebar">
        <h2 className="page-title text-center">Disability Development Program</h2>
        <div class="d-flex justify-content-center">
          <div class="dynamic-column text-center breadcrumbs p-3 ">
            Home | <span>Disability Development Program</span>
          </div>
        </div>
      </div>
    </div>
    </section>
    
    <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
      <h2 className='vasd_h3 text-center'>Disability Development Program</h2>
      <p>
      <h4 className='vasd_h4 text-center'>VASD provided services to near about 2100 persons with disabilities.</h4>

<h5 className='vasd_h5'>Following major activities have been implementing under the program:</h5>
<ul>
<li>Awareness raising of community people on disability issue</li>
<li>Identification of persons with disabilities</li>
<li>Assessment disability of identified persons with disabilities</li>
<li>Awareness rising and capacity building of persons with disabilities</li>
<li>Provide primary Rehabilitation Therapy [PRT] to persons with Disabilities</li>
<li>Provide referral services to persons with Disabilities in different specialized hospital/service centers</li>
<li>Provide medical services to persons with disabilities</li>
<li>Provide Assistive Devices to persons with disabilities</li>
<li>Provide life skill training to person with disabilities/families members</li>
<li>Provide loan support at less interest among the disabled people  to rehabilitate them in various IGA sector</li>
<li>Enroll children with disabilities into school and provide education support</li>
<li>Make schools disable children friendly</li>
<li>Form Self-help Group of persons with disabilities and building capacity</li>
<li>Advocacy and liaison with different government and non-government service providing institutes in favor of persons with disabilities</li>
<li>Networking with other disable based organization, forum and stakeholders</li>
 </ul>
      </p>
   
      </div>
        </div>

      </div>
    </section>
  );
};
export default DisabilityDevelopmentProgram;
