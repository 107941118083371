import React from 'react';
import '../style/DonateNow.css';

const DonateNow = ({ onDonateClick, buttonText = "Donate Now" }) => {
  return (
    <div className="donate-now-container">
      <h2 className="donate-title">Support Our Cause</h2>
      <p className="donate-description">
        Your contribution makes a difference! Help us achieve our goals by donating today.
      </p>
      <button className="donate-button" onClick={onDonateClick}>
        {buttonText}
      </button>
    </div>
  );
};

export default DonateNow;
