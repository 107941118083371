import React from 'react';

const LivelihoodExtension = () => {
  return (
    <section>
    <section>
      <div className="container-fluid">
        <div className="row page-titlebar">
          <h2 className="page-title text-center">Livelihood Extension
          </h2>
          <div class="d-flex justify-content-center">
            <div class="dynamic-column text-center breadcrumbs p-3 ">
              Home | <span>Livelihood Extension
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="container">
      <div className="row">
        <div className="col-md-12 pt-5 pb-5">
        <h2 className='vasd_h2 text-center'>Livelihood Extension</h2>

  
 
  
    </div>
  </div>
</div>

  </section >
  );
};

export default LivelihoodExtension;
