import React from 'react';

const AtAGlance = () => {
  return (
<section>
    <section>
    <div className="container-fluid">
      <div className="row page-titlebar">
        <h2 className="page-title text-center">At A Glance </h2>
        <div class="d-flex justify-content-center">
          <div class="dynamic-column text-center breadcrumbs p-3 ">
            Home | <span>At A Glance</span>
          </div>
        </div>
      </div>
    </div>
    </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
           <h1 className='text-center vasd_h2'>At A Glance</h1>
           <ol>
          <li> <span className='vasd_h5'>Name of the Organization 		: </span>  Village Association for Social Development</li>
          <li><span className='vasd_h5'>Acronym                                  	        : </span>  VASD</li>
          <li><span className='vasd_h5'>Name of President/Chairman           : </span>Md. Zahirul Haque </li>
          <li><span className='vasd_h5'>Number of General Assembly members :</span> 23 people </li>
          <li><span className='vasd_h5'>Number of executive council members :</span> 07 people </li>
          <li><span className='vasd_h5'>Founder Executive Director Name  :</span> MA Samad </li>
          <li><span className='vasd_h5'>Current Executive Director Name  :</span> Masuma Samad </li>
          <li><span className='vasd_h5'>Total number of permanent staff    : </span>38 people </li>
          <li><span className='vasd_h5'>Number of part time staff                : </span> 0 </li>
          <li><span className='vasd_h5'>Number of staff for various projects:</span> 12 people</li>
</ol>
          </div>
        </div>

      </div>
      </section>
 

  );
};

export default AtAGlance;
