// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import reactLogo from '../logo.png';
import '../style/Header.css';

const Header = () => {
    return (
        <nav className="navbar navbar-expand-lg  bg-white shadow-sm">
            <div className="container">
                {/* Logo on the Left */}
                <a className="navbar-brand" href="/">
                    <img src={reactLogo} alt="React Logo" className="logo" />
                    {/* <img
                        src="https://via.placeholder.com/150x50" // Replace with your logo URL
                        alt="Logo"
                        width="150"
                        height="50"
                    /> */}
                   </a>

                {/* Navbar Toggler for Mobile View */}
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* Navbar Menu on the Right */}
                <div className="collapse navbar-collapse " id="navbarNav">
                    <ul className="navbar-nav ms-auto vasd-navbar"> {/* ms-auto aligns the items to the right */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                Home
                            </Link>
                        </li>

                        {/* Dropdown Menu */}
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                About Us
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                <a className="dropdown-item" href="/about-us">
                                        About VASD
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/chairman-message">
                                        Message Of Chairman
                                    </a>
                                </li>

                                <li>
                                    <a className="dropdown-item" href="/executive-Director-message">
                                        Message Of Executive Director
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/at-a-glance">
                                        VASD At A Glance
                                    </a>
                                </li>
                                <li>
                             <Link  className="dropdown-item" to="/previous-project">Previous Project</Link>
                                </li>
                                <li>
                             <Link  className="dropdown-item" to="/donner">Donner</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Ongoing Program
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <a className="dropdown-item" href="/disability-development-program">
                                        Disability Development Program
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/livelihood-extension">
                                    Livelihood Extension
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/sampreeti">
                                        SAMPREETI
                                    </a>
                                </li>
                                <li>

                                </li>
                                <li>
                           <a className="dropdown-item" href="/hands_for-humanity">
                                    Hands for Humanity
                                    </a>
                                </li>

                                <li>
                                    <a className="dropdown-item" href="/rural-housing">
                                        Rural Housing
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="/micro-finance-program">
                                        Micro Finance Program
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            {/* <a className="nav-link" href="/resources">
                                Resources
                            </a> */}
                            <a className="nav-link" href="/">
                                Resources
                            </a>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to="/contact-us" style={{ textDecoration: 'none' }}>Contact</Link>
                        </li>
                    </ul>
                    <a type="button" class="btn-donation d-none d-sm-block"  href="/" >SINCE 1997

</a>
                </div>
            </div>
        </nav>
    );
};

export default Header;
