// src/pages/Home.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure to import Bootstrap CSS
import Banner from './Banner';
import OngoingProgramHomeSection from './OngoingProgramHomeSection';
import mra from '../assets/logo/mra.png';
import nab from '../assets/logo/nab.png';
import dss from '../assets/logo/dss.jpg';
import wab from '../assets/logo/bd.png';


const Home = () => {
  const iconStyle = {
    color: '#002866',

  };
  return (

    <section>
      <Banner />
      <OngoingProgramHomeSection />


      <section className='home_statics py-5 text-wrap'>

        <div className="container">
          <div className="row p-4">
            <div className="col-12  col-sm-5  pe-sm-5 mb-5 mb-sm-0" >
              <h3 className='vasd_h3 text-center text-sm-end'>Portfolio of Micro credit</h3>
              <h4 className='vasd-h4 text-center text-sm-end'>(Up to June 2023) </h4>
              <h1 className="vasd_h1 text-center text-sm-end  fs-1"> 13,35,06,567 cr</h1>
              <h4 className="vasd_h4 text-center text-sm-end"> Loan outstanding</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1"> 412</h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of groups</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1">   4546   </h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of members</h4>
              <h1 className="vasd_h1 text-center text-sm-end fs-1">   3570   </h1>
              <h4 className="vasd_h4 text-center text-sm-end">  No. of Borrowers</h4>

            </div>
            <div className="col-12 col-sm-7 ">
              <h2 className="vasd_h2 text-center text-sm-start fs-3" >

                Village Association for Social Development </h2>
              <h1 className="banner_h1 text-center text-sm-start fs-1">  VISION</h1>

              VASD envisage a poverty free, just and harmonious society where people live in peace with dignity.

              <h1 className="banner_h1 text-center text-sm-start fs-1">  MISSION</h1>

              VASD will raise people’s awareness on socio-economic development, organize and build up people’s capacity to implement development activities and achieve rights and entitlements.
              <div className="col-md-10 d-flex justify-content-center align-items-center mt-5">
                <a type="button" className="btn-contact" href="/about-us">About Us</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='mt-5 mb-5'>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h1 className='vasd_h1 text-center mb-sm-5 mb-2 fs-2'>MAJOR OBJECTIVES</h1>
              <ul className="list-unstyled">
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  To provide target people with proper knowledge, skill and attitude</li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure beneficiaries’ access to basic services</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure economic empowerment of the target people</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To achieve empowerment of the women</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> To ensure communities’ participation in mobilizing local resources </li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  To ensure social inclusion of the marginalized people</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> Building disability inclusive society and protect rights of the people with disability</li>
                <li> <i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i> Environment conservation and adaptation with natural calamities</li>
                <li><i class="bi bi-arrow-right-circle-fill " style={iconStyle}></i>  Building resilience to natural shocks. </li>
              </ul>
            </div>

            {/* <!-- Right side: Bangladesh Map --> */}
            <div class="col-md-6">
              <div class="map-container position-relative">
                <div class="">
                  <h1 className='vasd_h1 text-center mb-sm-5 mb-2 fs-2'>CORE VALUES</h1>
                  <h4 className='vasd_h4'>VASD is committed to its fundamental beliefs, that are - </h4>
                  <ul className="list-unstyled">
                    <li> <i class="bi bi-check-circle"></i> Professionalism </li>
                    <li> <i class="bi bi-check-circle"></i>  Transparency</li>
                    <li>  <i class="bi bi-check-circle"></i>  Accountability</li>
                    <li><i class="bi bi-check-circle"></i> Participation</li>
                    <li> <i class="bi bi-check-circle"></i> Cooperation</li>
                    <li> <i class="bi bi-check-circle"></i> Credibility</li>
                    <li> <i class="bi bi-check-circle"></i> Equity</li>
                    <li> <i class="bi bi-check-circle"></i> Innovation</li>
                    <li> <i class="bi bi-check-circle"></i> Dynamismv</li>
                    <li> <i class="bi bi-check-circle"></i> Gender responsiveness.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='p-3 p-sm-5 mb-3 mb-sm-5 mt-3 mt-sm-5' style={{ backgroundColor: '#FFEE00', }}>
        <div className="container">
          <div className="row">
            <div className='col-12 col-sm-6 d-flex justify-content-center align-items-center '>
              <h3 className='vasd_h3 text-center'>Direct Contact </h3>
            </div>
            <div className='col-12 col-sm-6  d-flex justify-content-center align-items-center'>

              <div className="d-flex align-items-center">
                <div className="me-4 fs-1">

                  <i className="bi bi-headset " style={{ backgroundColor: '#FFEE00', Color: '#FFF', }}></i>

                </div>
                <div>
                  <h3 className='fs-4'><strong>8801711 563174 </strong></h3>
                  <p>
                    <strong>Executive Director</strong>
                  </p>
                </div>
              </div>
           
            </div>
          </div></div>
      </section>
      <section >
        <div className="container">
          <div className="row">
            <div className='col-md-12'>
              <div className='vasd_h1 text-center mb-sm-5 mb-2 fs-1'> Member of Network Or Forum</div>
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th className='text-center'>Network OR Forum</th>
                    <th className='text-center'>Forum Identity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>National Forum of Organization Working with Disabled (NFOWD)</td>
                    <td>A Non-Govt. National organization working on disabled people</td>
                  </tr>
                  <tr>
                    <td>Association of Development Agencies in Bangladesh (ADAB)</td>
                    <td>Association of NGO’s in Bangladesh</td>
                  </tr>
                  <tr>
                    <td>NGO Forum for Safe Water Supply & Sanitation</td>
                    <td>Department of Public Health & Education a National Public Health control Sector.</td>
                  </tr>
                  <tr>
                    <td>Credit and Development Forum (CDF)</td>
                    <td>A Non-Govt. credit development & Management Organization</td>
                  </tr>
                  <tr>
                    <td>Podokhep consortium on HIV/AIDS</td>
                    <td>A National and International (Save the Children USA) joint venture organization working on HIV/AIDS</td>
                  </tr>
                  <tr>
                    <td>Bangladesh Manobadhikar Samonnoy Parishad (BMSP)</td>
                    <td>A National Non-Govt. organization working on Human Rights</td>
                  </tr>
                  <tr>
                    <td>Coalition for the Urban Poor (CUP)</td>
                    <td>A National Forum of organization who working with urban poor.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </section>    {/*end network */}
      <section >
        <div className="container mb-sm-5 mb-2">
          <div className="row">
            <div className='vasd_h1 text-center mb-sm-5 mb-2 fs-1'> Legal Status</div>
            <div className=" col-12 col-sm-3">
              <div className="  d-flex flex-column align-items-center image-container">
                <img src={dss} alt="Department of Social Service" />
                <h5 className="text-center vasd_h5">Department of Social Service</h5>
              </div> </div>
            <div className=" col-12 col-sm-3">
              <div class="  d-flex flex-column align-items-center image-container">
                <img src={nab} alt="Example" />
                <h5 class="text-center vasd_h5">NGO Affairs Bureau</h5>
              </div></div>
            <div className=" col-12 col-sm-3">
              <div class=" d-flex flex-column align-items-center image-container">
                <img src={wab} alt="Example" />
                <h5 class="text-center vasd_h5">Department of Women Affairs</h5>
              </div></div>
            <div className=" col-12 col-sm-3">
              <div class="d-flex flex-column align-items-center image-container">
                <img src={mra} alt="Example" />
                <h5 class="text-center vasd_h5">Micro Credit Regulatory Authority (MRA)</h5>
              </div></div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Home;