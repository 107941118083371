import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Headermenu';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ChairmanMessage from './components/ChairmanMessage';
import ExecutiveDirectorMessage from './components/ExecutiveDirectorMessage';
import AtAGlance from './components/AtAGlance';
import DisabilityDevelopmentProgram from './components/DisabilityDevelopmentProgram';
import Sampreeti from './components/Sampreeti';
import VGDProgram from './components/VGDProgram';
import RuralHousing from './components/RuralHousing';
import MicroFinanceProgram from './components/MicroFinanceProgram';
import ContactUs from './components/ContactUs';
import Resources from './components/Resources';
import Donner from './components/Donner';
import DonateNow from './components/DonateNow';
import LivelihoodExtension from './components/LivelihoodExtension';
import HandsForHumanity from './components/HandsForHumanity';
import PreviousProject from './components/PreviousProject';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS for styling
import 'bootstrap-icons/font/bootstrap-icons.css';
import FooterLarge from './components/FooterLarge';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Header is displayed on all pages */}
        <Header />

        {/* Define Routes for different pages */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/chairman-message" element={<ChairmanMessage />} />
            <Route path="/executive-director-message" element={<ExecutiveDirectorMessage />} />
            <Route path="/at-a-glance" element={<AtAGlance />} />
            <Route path="/disability-development-program" element={<DisabilityDevelopmentProgram />} />
            <Route path="/livelihood-extension" element={<LivelihoodExtension />} />
            <Route path="/hands_for-humanity" element={<HandsForHumanity />} />
            <Route path="/sampreeti" element={<Sampreeti />} />
            <Route path="/vgd" element={<VGDProgram />} />
            <Route path="/rural-housing" element={<RuralHousing />} />
            <Route path="/micro-finance-program" element={<MicroFinanceProgram />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/donner" element={<Donner />} />
            <Route path="/previous-project" element={<PreviousProject />} />
            <Route path="/donate-now" element={<DonateNow />} />
          </Routes>
        </main>

        {/* Footer is displayed on all pages */}
        <FooterLarge />
      </div>
    </Router>
  );
}

export default App;
