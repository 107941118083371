import React from 'react';

const VGDProgram = () => {
  return (
    <section>
    <section>
    <div className="container-fluid">
      <div className="row page-titlebar">
        <h2 className="page-title text-center">Vulnerable Group Development (VGD) Program</h2>
        <div class="d-flex justify-content-center">
          <div class="dynamic-column text-center breadcrumbs p-3 ">
            Home | <span>Vulnerable Group Development (VGD) Program</span>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
          <h2 className='vasd_h3 text-center'>Vulnerable Group Development (VGD) Program
      </h2>
  
      <p>The Department of Women Affairs under the Ministry of Women and Children Affairs has been implementing Development support service (Skill training, Savings and access to Credit) of the VGD program by NGOs through out the country. VASD is selected as NGO through bidding process to provide development supports to target women.</p>

<p><strong>Objective:</strong> The main objectives are building  the income generating capacities of ultra poor  women and make them socially empowered providing training on awareness raising, income generating activities, savings facility, access to credit and other support services during and beyond the food assistance period.</p>


<p><strong>Target people: </strong>The Ultra poor women [can’t manage one meal in a day, sub standard house,   landless, female headed family, no fixed income source and so on]</p>

<p><strong>Donor/Partner: </strong> The Department of Women Affairs under the Ministry of Women and Children Affairs</p>

<p><strong>Working area: </strong>Daulatpur, Ghior and Horirampur 3 Upazilas of Manikgonj District. There are 8 Unions in Daulatpur Upazila, 7 Unions in Ghior Upzila and 9 Unions in Horirampur Upazila.</p>

<p><strong>Project period: </strong> January 2011 – continued</p>
<p>
<strong>Major Activities:</strong>
<ul>
  <li>Organize the selected women</li>
  <li> Raising awareness of women on social and economic issues</li>
  <li>Provide Life Skills training (human skill and IGAs skill )to Ultra poor women such as  
  <ul>
  <li>
  <strong>Social awareness :</strong> Personal Hygiene and Cleanliness, Food and Nutrition,  Mother and Child Health, Gender Equality and Women Empowerment,  HIV/AIDS  Prevention,   Risk Management of Natural Disasters
</li>
<li>
<strong>IGA skills:</strong> Cow & goat rearing, Poultry rearing, Vegetable Gardening,                 Entrepreneurship Development.
</li>
</ul>
</li>

<li>Mobilization of forced savings to form capital of women</li>

<li>Link the women to financial organization to have micro credit support</li>

<li>Link women to government and non government service providing organization to have needed supports for livelihood</li>

<li>Advocacy and liaison with different service providing institution in favor women interest</li>

</ul></p>

</div>

</div>

</div>
    </section>
  );
};

export default VGDProgram;
