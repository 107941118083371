import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Make sure Bootstrap is imported
import '../style/Banner.css'; // Make sure Bootstrap is imported


const Banner = () => {
  return (
    <section className="banner-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-8  col-12  pt-5">

            <h1 className="banner_h1"> VASD</h1>
            <h2 className="vasd_h2" >

              Village Association for Social Development </h2>
            <p>A non-profitable, non-political, non-governmental voluntary development organization</p>

            {/* <h1 className="banner_h1">  VISION</h1>

            VASD envisage a poverty free, just and harmonious society where people live in peace with dignity.

            <h1 className="banner_h1">  MISSION</h1>

            VASD will raise people’s awareness on socio-economic development, organize and build up people’s capacity to implement development activities and achieve rights and entitlements.
 */}

            <div className='row mt-5'>
              <div className="col-md-4 d-flex justify-content-center align-items-center">
                <a type="button" className="btn-contact" href="/ContactUs">Contact Us</a>
              </div>
              {/* <div className="col-md-4 d-flex justify-content-center align-items-center">
                <a type="button" className="btn-donations" href="/donate-now" >Donate Now</a>
              </div> */}
            </div>

          </div>
        </div>

      </div>
    </section>


  );
}

export default Banner;
