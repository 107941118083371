import React from 'react';
import about from '../assets/images/about.jpg';

const AboutUs = () => {
  return (

    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">About Us </h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>About Us </span>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="py-5 ">
        <div className="container">
          <div className="row">
            <h1 className='text-center vasd_h3'>ORGANIZATIONAL OVERVIEW</h1>
            <div className="col-12 col-sm-5 pt-5 pb-5">
              <p className='m-2'>
                <img src={about} alt="React Logo" className="img-fluid" />
              </p>
            </div>
            <div className="col-12 col-sm-7 pt-5 pb-5">
              <p className='m-2'> Village Association for Social Development (VASD) was started in a small village of Manikganj district from the development thinking of some of the rural activists in 1995. With an organizational approach of innovation and learning that had been the essential components, VASD has entered into its 23rd years of development initiatives. During this good period of time, many changes have been brought with its perceptions of new needs, priorities and new expectations. Even innovation has been remained firmly committed to its mission. During the period, the organisation has acquired diverse experience from the real field of implementation. However, VASD has endeavored to be sensitive and responsive.
              </p>
              <p> Since its inception in 1995, (VASD) has been a visionary and people-centric organisation that established VASD as a popular organisation to the people it works with. Keeping the same commitment and determination it has applied to its mission, it has been continuing its mission very successfully. The principles of objectivity, built on accountability, efficiency and effectiveness, which VASD adopted since the very beginning, have remained immutable in the face of all the challenges the organization has encountered. These key tenets have been at the core of the initiatives that VASD has undertaken over the years.
              </p>
              <p> Presently VASD is playing an important role which acts as a catalyst for development to achieve inclusion of the people who are excluded socially, politically, economically and by many other means. It has been striving to build trust, strengthen communication and foster productive relationships both within the organization as well as with its program participants. Through pursuing its objective of reducing poverty, supporting human rights and very particularly improving the status of women and the people with disabilities. Thus the organisation is making efforts to bring about changes in the lives of the people.
              </p>

            </div>
          </div>

        </div>
      </div>
    </section>

  );
};

export default AboutUs;
