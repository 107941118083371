import React, { useRef } from 'react';
import '../style/OngoingProgramHomeSection.css';

import disability_development from '../assets/images/disability_development.jpg';
import smmpreeti from '../assets/images/smmpreeti.jpg';
import hands_for_humanilty from '../assets/images/hands_for_humanilty.jpg';
import livelhood_extension from '../assets/images/livelhood_extension.jpg';
import micro_fnance from '../assets/images/micro_fnance.jpg';
import rural_housing from '../assets/images/rural_housing.jpg';

const OngoingProgramHomeSection = () => {
  const scrollRef = useRef(null);

  // Scroll function to move cards horizontally
  const scroll = (direction) => {
    if (direction === 'left') {
      scrollRef.current.scrollBy({ left: -400, behavior: 'smooth' });
    } else {
      scrollRef.current.scrollBy({ left: 400, behavior: 'smooth' });
    }
  };

  // Sample data with vibrant images
  const programs = [
    { id: 1, title: 'Disability Development Program', description: '', image: disability_development, link: '/disability-development-program' },
    { id: 2, title: 'SAMPREETI Program', description: '', image: smmpreeti, link: '/sampreeti' },
    { id: 3, title: 'Hands For Humanilty', description: '', image: hands_for_humanilty, link: '/hands_for-humanity' },
    { id: 4, title: 'Rural Housing', description: '', image: rural_housing, link: '/rural-housing' },
    { id: 5, title: 'Micro Finance Program', description: '', image: micro_fnance, link: '/micro-finance-program' },
  ];

  return (
    <div className="container-fluid my-5 position-relative">
      <h2 className="text-center mb-4 vasd_h2">Ongoing Programs</h2>

      {/* Scroll Buttons */}
      <button className="scroll-button left-btn" onClick={() => scroll('left')}>
        &#8592;
      </button>
      <button className="scroll-button right-btn" onClick={() => scroll('right')}>
        &#8594;
      </button>

      {/* Card Container */}
      <div className="card-container row flex-nowrap overflow-auto" ref={scrollRef}>
        {programs.map((program) => (
          <div
            className="program-card col-md-3 mx-3"
            key={program.id}
            style={{
              backgroundImage: `url(${program.image})`,
            }}
          >
            <div className="card-overlay">
              <h5 className="card-title">{program.title}</h5>
              <p className="card-description">{program.description}</p>
              <a href={program.link} className="details-button">
                View Details
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OngoingProgramHomeSection;
