import React from 'react';

const RuralHousing = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Rural Housing</h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Rural Housing</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">

            <h2 className='vasd_h2 text-center'>Rural Housing</h2>
           
             <p> Most of the ultra poor/hardcore female headed women are living in hut which is substandard and not health friendly as well as insecure. They have no financial ability to build a standard house for health, security as well as social dignity. Other hand, some have been affected and/or have lost their houses because of disaster like seasonal storms, river erosion they could not build housing due to lack of financial capital.</p>
             <p> To address the problem, selected women are being provided with 50,000-80,000 taka loan for house making and assists in building house through cooperating in all necessary arrangements. The house loan borrowers repay the loan by installment.</p>
             <strong> Objectives of Rural Housing:</strong>
             <ul >
             <li> A. To provide moderate housing facilities among the rural poor to rehabilitate the families who have been affected disaster like seasonal storms, river erosion etc.</li>
             <li> B.  Protect the public health and aware them about diarrohea and cold diseases and so on
             <ul >
             <li>  <strong> Beneficiaries: </strong>Hardcore women/persons with disabilities family </li>
             <li> <strong>Donor/Partner: </strong>Bangladesh Bank [Grehayan tahabil]</li>
             <li> <strong> Working area:</strong>Savar & Dhamrai upazila in Dhaka and Manikgonj district</li>
             <li> <strong> Project duration: </strong>2003 to till
             <ul >
              Activities:
              <li> Selection of beneficiaries’ women in according with set criteria</li>
              <li>Provide loan 50,000-80,000 taka to per person/house</li>
              <li>Assist them in arranging house materials and establish houses</li>
              <li>Provide counseling support for using house environment spoonful</li>
              <li>Collect installment of house loan installment from house recipients</li>
              <li>Report to Grihayan Tohabil as directed</li>
              </ul></li>
              </ul>
              </li>
              <li> C. So far VASD has distributed 1200 houses among 1200 families.</li>
              </ul>
            
         

          </div>

        </div>

      </div>
    </section>
  );
};



export default RuralHousing;
