import React from 'react';

const Sampreeti = () => {
  return (
    <section>
    <section>
    <div className="container-fluid">
      <div className="row page-titlebar">
        <h2 className="page-title text-center">SAMPREETI</h2>
        <div class="d-flex justify-content-center">
          <div class="dynamic-column text-center breadcrumbs p-3 ">
            Home | <span>SAMPREETI</span>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
    <h4>Social Action and Mobilization for Prevention of Radicalization and Extremism through Enhanced and Targeted Interventions Project (SAMPREETI).</h4>
     
    <p>  The Department of Women Affairs under the Ministry of Women and Children Affairs has been implementing Development support service (Skill training, Savings and access to Credit) of the VGD program by NGOs through out the country. VASD is selected as NGO through bidding process to provide development supports to target women.
    </p>
<p>
<strong>Objective:</strong> To contribute to strengthening the capacities of local civil society actors (including youth, parents, duty bearers, education institutions, media, religious groups) to prevent radicalization and violent extremism.
</p>
<strong> Target people:</strong>
<ul>

 <li>Students of Graduation level</li>
 <li>Parents & Guardians</li>
 <li>Teachers & MC</li>
 <li>Duty Bearers & Public Representatives</li>
</ul>
<ul>
<li>Donor/Partner: Manusher Jonno Foundation (MJF)s/</li>
<li>Working area: Four Upazelas of Manikganj district; Manikganj Sadar, Ghior, Harirampur and Singair. </li> 
<li>Project period: November 2018 – continued</li>
 </ul>
<p><strong>Major Activities:</strong></p> 
<li>Orientation of students, parents, Teachers and Managing Committee on the prevention of radicalization and violent extremism</li>
<li>Workshop on the prevention of radicalization and violent extremism</li>
<li>Organising cultural activities (Folk song, Day observation etc.)</li>
<li>Art competition and Essay competition on Radicalization and extremism</li>
<li>Street campaign and Signature campaign</li>
<li>Media Campaign (Advertising on local dish channels)</li>
<li>Poster printing and dissemination.</li>
</div>
        </div>

      </div>

    </section>
  );
};


export default Sampreeti;
