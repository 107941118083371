import React from 'react';

const Donner = () => {
  return (
    <section>

      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">DONOR / DEVELOPMENT PARTNERS </h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>DONOR / DEVELOPMENT PARTNERS</span>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="py-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-5 pb-5">


              <h1 className='text-center vasd_h3'>  Present Donor/Partner</h1>
              <ul>
                <li> Department of Women Affairs/GoB</li>
                <li>Bangladesh NGO Foundation (BNF) /GoB</li>
                <li> Jatiya Protibondi Unnayan Foundation (JPUF)/ GoB</li>
                <li> Bangladesh Bank [Grihyon Tohabil]/GoB</li>
                <li> Basic Bank limited, Bangladesh</li>
                <li> Midland Bank Ltd.</li>
                <li> Standard Bank limited, Bangladesh</li>
                <li> Bangladesh Commerce Bank Ltd.</li>
              </ul>
              <h1 className='text-center vasd_h3'>   Previous Donor/Partner</h1>
              <ul>
                <li>Centre for Disability in Development (CDD)/AusAID/ CBM)</li>
                <li> LCD-Bangladesh</li>
                <li> Holiday INN-UK</li>
                <li> World Vision, Concern BD and Podokhep</li>
                <li> VERC/CORDIATE The Netherlands</li>
                <li> Save the Children USA/Podokhep</li>
                <li> Department of Non-Formal Education (DNFE)/GoB</li>
                <li> Department of Social Service (DSS)/GoB</li>
                <li> Proshika-MUK</li>
                <li>Secondary School Assistance Project/GoB</li>
                <li>CARE  Bangladesh</li>
                <li> Bureau of Non formal education/GoB</li>
                <li> NGO Forum for Safe Water Supply & Sanitation</li>
                <li> German Technical cooperation /gtz</li>
                <li> JAKJARA, a software company</li>
              </ul>

            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Donner;
