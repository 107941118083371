import React from 'react';

const FooterLarge = () => {
    return (
        <section className="text-white py-5 footerbg">
            <div className="container">
                <div className="row">
                    {/* Column 1 */}
                    <div className="col-md-4 text-center text-sm-start">
                        <h5 className="footerbg_title">About Us</h5>
                        <p>
                        A non-profitable, non-political, non-governmental voluntary development organization.
                        </p>
                    </div>

                    {/* Column 2 */}
                    <div className="col-md-4">
                        <h5 className="footerbg_title text-center text-sm-start">Quick Links</h5>
                        <ul className="list-unstyled ">
                            <li> <i class="bi bi-caret-right text-color-yellow"></i> <a href="/" className="text-white text-decoration-none">Home</a></li>
                            <li><i class="bi bi-caret-right text-color-yellow"></i><a href="/about-us" className="text-white text-decoration-none">About Us</a></li>
                            <li><i class="bi bi-caret-right text-color-yellow"></i><a href="/donner" className="text-white text-decoration-none">Donner List</a></li>
                            <li><i class="bi bi-caret-right text-color-yellow"></i><a href="/contact-us" className="text-white text-decoration-none">Contact Us</a></li>
                        </ul>
                    </div>

                    {/* Column 3 */}
                    <div className="col-md-4 text-center text-sm-start">
                        <h5 className="footerbg_title ">Contact Us</h5>
                        <p>
                            Email: vasd1995@yahoo.com <br />
                            Mobile: +8801711 563174
                        </p>
                        <p className="footerbg_title text-center text-sm-start">Follow Us</p>
                        <div className='text-center text-sm-start'>
                        <span className='vasd_h2 text-color-yellow m-2'><i class="bi bi-facebook"></i></span> 
                       <span className='vasd_h2 text-color-yellow m-2'><i class="bi bi-twitter-x"></i></span> 
                       </div>
                    </div>
                </div>

                <hr className="my-4" />
                <div className="text-center ">
                    <p className="mb-0"> <span className='text-color-yellow'>©</span> {new Date().getFullYear()} VASD. All rights reserved.</p>
                </div>
            </div>
        </section>
    );
};

export default FooterLarge;
