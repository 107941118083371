import React from 'react';

const MicroFinanceProgram = () => {
  return (
    <section>
      <section>
        <div className="container-fluid">
          <div className="row page-titlebar">
            <h2 className="page-title text-center">Micro Finance Program
            </h2>
            <div class="d-flex justify-content-center">
              <div class="dynamic-column text-center breadcrumbs p-3 ">
                Home | <span>Micro Finance Program
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 pt-5 pb-5">
          <h2 className='vasd_h2 text-center'>Micro Finance Program</h2>

       <p> VASD started micro finance program since 1995 at the aim to uplift economic growth of the beneficiaries. The program has 3 components such as micro credit, savings mobilization, deposit scheme. Micro loans are divided into two areas like Rural micro credit and Micro Enterprises. Major IGAs are agriculture, vegetable cultivation, cattle rearing, poultry rearing, small business, ferry/hawkary business, grocery shop, tea stall, garments, nursery, Rickshaw/Van, auto rickshaw, handicraft and so on.</p>
       <p>Targeted people are organized in group which consists of 20-25 members. Rise group awareness on economic and social issues than provided loan to needy members through ensuring scheme/project feasibility study.</p>
      <h4 className='vasd_h4'> Objectives of program:</h4>
       <ol>
       <li> To increase income of the target beneficiaries</li>
       <li> To create employment opportunities for targeted beneficiaries</li>
       <li> To increase purchasing capacity of the poor beneficiaries</li>
       <li> To increase socio-economic status of the beneficiaries</li>
       <li> To reduce poverty of the country</li>
       <li> To contribute in Gross Domestic Production of the country</li>
       </ol>
       <ol>
        <li> Beneficiaries: Poor people, marginal farmers/business people, persons with disabilities</li>

        <li>Working Area: Manikgonj and Dhaka district</li>

        <li>Funded by: 
          <ol>
       <li> Own fund </li>
       <li> Basic Bank Limited </li> 
       <li>  Midland Bank Limited </li> 
       <li> Standard Bank limited</li> 
       <li>  Bangladesh Commerce Bank Ltd. </li> 
       <li>  Bangladesh Commerce Bank Ltd. </li> 
       <li> Bangladesh Bank Grihayan Tohabil</li>
        </ol>
        </li>
        <li>
        <ol>
       Portfolio of Micro credit: (Up to June 2023)
        <li> Loan outstanding: Tk. 13,35,06,567</li>
        <li> No. of groups: 422</li>
        <li> No. of members: 4546</li>
        <li> No. of Borrowers: 3570</li>
        </ol>
        </li>
        </ol>
      </div>
    </div>
</div>

    </section >
  );
};



export default MicroFinanceProgram;
